<template>
    <div>
        <div class="card">
            <div class="row justify-content-end">
                <div class="col-sm-3 d-flex position-absolute justify-content-end gap-3 mt-3 me-2 pt-1">
                    <button @click.prevent="onAddMeterClick()" class="btn btn-sm btn-primary rounded-2">
                        Add Meters
                    </button>
                    <button @click.prevent="onDetachMeterClick()" class="btn btn-sm btn-danger rounded-2">
                        Detach Meters
                    </button>
                    <div></div>
                </div>
            </div>
            <datatable tableId="meterTable" :columns="columns" :data="meters" :showLoading="meters === null" />
        </div>
    </div>
</template>
<script>
import { mapActions } from "vuex";
import Datatable from '../../../components/Datatable.vue';
export default {
    components: { Datatable },
    data() {
        return {
            columns: [
                { data: "label" },
                { data: "metrics_data_channel", title: "metrics data channel" },
                { data: "metrics_type", title: "metrics type" },
                { data: "metrics_field", title: "metrics field" }
            ]
        };
    },
    props: ["meters"],
    mounted() {
        this.$emit('showOverrideEdit', false);
        this.$emit('setOverrideEditMode', false);
    },
    emits: ['showOverrideEdit', 'setOverrideEditMode'],
    methods: {
        ...mapActions({
            showModal: "showModal",
        }),
        onAddMeterClick() {
            const jobDetails = {
                cid: this.$store.state.currentClient.cid,
                sandboxName: this.$route.params.sandboxId,
                jobName: this.$route.params.jobId,
                meters: this.meters,
            }
            this.showModal({ component: 'AddMeters', data: { jobDetails: jobDetails } });
        },
        onDetachMeterClick() {
            const jobDetails = {
                cid: this.$store.state.currentClient.cid,
                sandboxName: this.$route.params.sandboxId,
                jobName: this.$route.params.jobId,
            }
            this.showModal({ component: 'DetachMeters', data: { jobDetails: jobDetails } });
        },
        isProdSandbox() { // checks if the sandboxes listed are production or dev sandboxes
            return !this.$route.meta.sandboxTypes.includes("dev")
        },
        isAqferAdmin() { // checks if user is aqfer_admin
            return this.$store.state.currentUser.roles.includes("aqfer_admin")
        }
    }
};
</script>
<style></style>
