const Fields = [
    {
        name: "cid",
        label: "CID",
        type: "text",
        editable: false,
        validation: [
            { type: "required", errMsg: "CID is required" }
        ]
    },
    {
        name: "name",
        label: "Name",
        type: "text",
        editable: false,
        validation: [
            { type: "required", errMsg: "Client name is required" }
        ]
    },
    {
        name: 'organization_id',
        label: 'Organization ID',
        type: 'number',
        visible: true,
    },
    {
        name: "assignee_id",
        label: "Assignee ID",
        type: "text",
        editable: true,
    },
    {
        name: "notificationEmails",
        label: "Notification Email(s)",
        type: "multiselect",
        editable: true,
        tooltip:"List of email ids to which deployment information will be notified"
    },
    {
        name:'type',
        label: "Account Type",
        type: "select",
        editable: true,
        visible: true,
        options:[
            { label: "MAIN", value: "MAIN" },
            { label: "PRIMARY_TENANT", value: "PRIMARY_TENANT" },
            { label: "SUBTENANT", value: "SUBTENANT" }
        ]   
    },
    {
        name: "parent_id",
        label: "Parent ID",
        type: "text",
        editable: true,
    },
    {
        name: "config",
        label: "Features",
        type: "object",
        tooltip:"Other configurations",
        inputType: "json",
        editable: true,
    }, 
];

export default Fields;

