<template>
    <detail-header
        pretitle="Clients"
        :title="client ? client.name : '&nbsp;'"
        :editable="editable"
        :editMode="editMode"
        @editClick="onEditClick"
        @deleteClick="onDeleteClick"
        @saveClick="onSaveClick"
        @cancelClick="onCancelClick"
        @closeClick="$router.push('/admin/clients')"
        :customMode="isAccessByUserRole(btnRoles)"
        :customModeText="isEnabled"
        :customBtnClass="isEnabled=='Enable'?'btn btn-success':'btn btn-danger'"
        @customClick="onEnableDisableClient"
    />

    <div class="container-fluid">
        <Tabs :tabs="tabItems">
            <template v-slot:settings>
                <div class="row">
                    <div class="col-12">
                        <div v-if="error" class="alert alert-danger" role="alert">
                            {{ error }}
                        </div>
                        <div
                            v-if="successMessage"
                            class="alert alert-success"
                            role="alert"
                        >
                            {{ successMessage }}
                        </div>
                        <div v-if="client" class="card mb-5">
                            <div class="card-body">
                                <form-fields
                                    :fields="fields"
                                    v-model="client"
                                    :editMode="editMode"
                                />
                            </div>
                        </div>
                        <div v-else class="text-center">
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:settingsV2>
                <div class="row">
                    <div class="col-12">
                        <div v-if="error" class="alert alert-danger" role="alert">
                            {{ error }}
                        </div>
                        <div
                            v-if="successMessage"
                            class="alert alert-success"
                            role="alert"
                        >
                            {{ successMessage }}
                        </div>
                        <div v-if="clientV2" class="card mb-5">
                            <div class="card-body">
                                <form-fields
                                    :fields="fieldsV2"
                                    v-model="clientV2"
                                    :editMode="editMode"
                                />
                            </div>
                        </div>
                        <div v-else class="text-center">
                            <h4 class="mt-4">No V2 settings detected for {{$route.params.id}}</h4>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:subscriptions>
                <div class="row" v-if="!loading">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header">
                                <h4></h4>
                                <button class="btn btn-primary float-right"
                                    @click="showModal({ component: 'addClientSubscription' })"><i class="fa fa-plus"></i></button>
                            </div>
                        <div>
                            <Datatable tableId="subscriptionTable" :columns="columns" uniqueField="id"
                                :data="clientProducts" :deletable="true" @deleteRows="showDeleteClientProducts" :compact="true"
                                :selectCheckboxes="true"
                                :formatColumn="formatColumn"
                                @viewModal="(data)=>showModal({ component: 'editClientSubscription',data:data})"
                                :detailColumn="{openModal:true}" />
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="row">
                    <div class="col-md-12">
                        <div class="text-center mt-5">
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            
        </Tabs>
    </div>
</template>

<script>
import DetailHeader from "../../components/DetailHeader.vue";
import Fields from "../../utils/field-constants/clientEditFields";
import FieldsV2 from "../../utils/field-constants/clientV2EditFields";
import { mapActions } from "vuex";
import FormFields from "../../components/FormFields.vue";
import Tabs from "../../components/Tabs.vue";
import Datatable from "../../components/Datatable.vue";
export default {
    name: "Client",
    components: { DetailHeader, FormFields, Tabs, Datatable },
    data() {
        var self=this;
        return {
            editMode: false,
            client: null,
            clientV2: null,
            snapshot: null,
            snapshotV2: null,
            error: "",
            successMessage: "",
            fields: Fields,
            fieldsV2: FieldsV2,
            editable: true,
            tabItems: [{
                    name: "aUT Settings",
                    id: "id1",
                    slot: "settings",
                    onClick: function(){
                        self.$router.replace({ path:`/admin/clients/${self.$route.params.id}`,
                                                query: {settings: "details"}})
                    }
                },{
                    name: "Client Settings",
                    id: "id3",
                    slot: "settingsV2",
                    onClick: function(){
                        self.$router.replace({ path:`/admin/clients/${self.$route.params.id}/settingsV2`})
                    }
                },{
                    name: "Subscriptions",
                    id: "id2",
                    slot: "subscriptions",
                    onClick: function(){
                        self.$router.replace({ path:`/admin/clients/${self.$route.params.id}/subscriptions`});
                    }    
                }],
            columns:[
                { data: "sku", title:"Product SKU" },
                { data: "quantity"},
                { data: "effective_date", title: "Effective Date"},
                { data: "end_date", title: "End Date" },
                { data: "status", title: "Status" }
            ],
            formatColumn: [
                {
                    index: 4,
                    type: "date",
                },
                {
                    index: 5,
                    type: "date",
                },
            ], 
            loading:false,
            btnRoles: [ 1 ]
        };
    },
    beforeMount() {
        this.fetchClient();
        this.fetchClientV2();
        if( this.activeTab =='Subscriptions'){
            this.$router.replace({ path:`/admin/clients/${this.$route.params.id}/subscriptions`});
        }else if(this.$route.path.includes("subscriptions")){
            this.$store.dispatch('setActiveTab',"Subscriptions");
        }
        if( this.activeTab =='Settings V2'){
            this.$router.replace({ path:`/admin/clients/${this.$route.params.id}/settingsV2`});
        }else if(this.$route.path.includes("settingsV2")){
            this.$store.dispatch('setActiveTab',"Settings V2");
        }
        this.$store.dispatch("aut/fetchPrivacyConfig",this.$route.params.id);
        this.fetchClientProducts(this.$route.params.id);
    },
    computed:{
        activeTab(){
            return this.$store.state.activeTab
        },
        clientProducts(){
            let activelist =this.$store.state.admin.clientProducts.map((sub)=>{
                let end_date =new Date(sub.end_date)
                sub.status = end_date> new Date() ? "Active": "Expired"
                return sub
            })
           return activelist
        },
        isEnabled(){
            return this.client?.enabled?"Disable":"Enable"
        }
    },
    watch: {
        "client": {
            handler: function(newData) {
                if(newData) { 
                    this.client.gpp.tcf.gvlId =   (this.client.gpp.tcf.status == "DISABLED"||this.client.gpp.tcf.gvlId == ''||this.client.gpp.tcf.gvlId == null)? null:Number(this.client.gpp.tcf.gvlId);                   
                    this.fields = this.hideFields(  this.fields, newData);
                }                
            },
            deep: true       
        }, 
        activeTab(newVal ) {
            this.editable=newVal=='Subscriptions'?false:true
        }

    },
    methods: {
        ...mapActions({
            readClient: "admin/readClient",
            updateClient: "admin/updateClient",
            enableDisableClient:"admin/enableDisableClient",
            deleteClient: "admin/deleteClient",
            readClientV2: "admin/readClientV2",
            updateClientV2: "admin/updateClientV2",
            showModal: "showModal",
            fetchClientProducts: "admin/fetchClientProducts"
        }),
        setEditMode(mode) {
            this.editMode = mode;
            this.successMessage = null;
            this.error = null;
        },
        async fetchClient() {
            let response;
            try {
                response = await this.readClient(this.$route.params.id);
            } catch (error) {
                if (error.response.data.error)
                    this.error = error.response.data.error;
                else this.error = error.message;
                return;
            }

            this.client = await response.data;
            this.client.eTag = response.headers['etag']
            this.snapshot = JSON.parse(JSON.stringify(this.client ));
        },
        async fetchClientV2() {
            let response;
            try {
                response = await this.readClientV2(this.$route.params.id);
            } catch (error) {
                console.log(error)
            }
            if (response) {
                this.clientV2 = await response.data;
                this.snapshotV2 = JSON.parse(JSON.stringify(this.clientV2));
            }
        },
        onCancelClick() {
            this.setEditMode(false);
            this.client = JSON.parse(JSON.stringify(this.snapshot ));
            this.clientV2 = JSON.parse(JSON.stringify(this.snapshotV2 ));
            this.error = null;
        },
        onEditClick() {
            this.setEditMode(true);
        },
        onDeleteClick() {
            const self = this;
            this.showModal({
                component: "confirm",
                data: {
                    title: "Delete Client",
                    message: `Are you sure you want to delete ${this.client.name}?`,
                    async confirm() {
                        try {
                            const response = await self.deletClient(
                                self.client
                            );
                            self.$router.push("/admin/clients");
                            return response;
                        } catch (error) {
                            if (error.response.data.error)
                                self.error = error.response.data.error;
                            else self.error = error.message;
                            return;
                        }
                    },
                },
            });
        },
        hideFields(fields, obj) {
            return fields.map((field) => {
                if(field.suppressIfField) {    
                    const val = field.suppressIfField.split('.').reduce((o, i) => o[i], obj);
                    if(field.suppressIfValueContains && field.suppressIfValueContains.indexOf(val) > -1)
                        field.visible = false;
                    else if(field.suppressIfValueDoesNotContains && field.suppressIfValueDoesNotContains.indexOf(val) == -1)
                        field.visible = false;    
                    else
                        field.visible = true;

                                            
                    return field;   
                    
                }
                return field;
            }); 
        },
        async onSaveClick() {
            let self = this;
            let clientObj = this.client;            
            try {
                const response = await this.updateClient(clientObj);
                this.setEditMode(false);
                this.snapshot = JSON.parse(JSON.stringify(clientObj));
                this.successMessage = "Client updated successfully.";
                // fetch client again to reset the eTag
                this.fetchClient();
            } catch (error) {
                if (error.response.data.error)
                    self.error = error.response.data.error;
                else self.error = error.message;
            }
            if(this.clientV2) {
                try {
                    const response = await this.updateClientV2(this.clientV2);
                    this.snapshotV2 = JSON.parse(JSON.stringify(this.clientV2));
                } catch (error) {
                    console.log(error)
                }
            }
        },
        showDeleteClientProducts(rows) {
            this.showModal({ component: "deleteClientProducts", data: rows });
        },
        async onEnableDisableClient() {
            var self= this;
            let clientObj = this.client;
            let toastoptions;
            this.showModal({
                component: "confirm",
                data: {
                    title:`${this.isEnabled} Client`,
                    message: `Are you sure you want to ${this.isEnabled.toLowerCase()} client ${this.client.cid}?`,
                    async confirm() {
                        try {
                            let msg = clientObj.enabled ?"Disabled" :"Enabled"
                            clientObj.enabled=!clientObj.enabled
                            await self.enableDisableClient(clientObj).then(()=>{
                                self.enabled=!clientObj.enabled;
                            });
                            toastoptions = {
                                message: `${msg} client successfully.`,
                                type: "success"
                            }
                            await self.fetchClient();
                        } catch (error) {
                            if (error.response.data.error)
                                self.error = error.response.data.error;
                            else self.error = error.message;
                            toastoptions = {
                                message: self.error || `Something went wrong. Please contact support.`,
                                type: "error",
                            };
                        } finally{
                            self.$store.dispatch('addToast', toastoptions);
                        }
                    },
                },
            });
            
        },
        isAccessByUserRole(roles) {
            return this.$store.getters.userRoles.isAccessByRole(roles);
        }
    },
};
</script>

<style>
</style>